<script>
import axios from "axios";

import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

import { required, helpers, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    newPassword: {
      required: helpers.withMessage("Password is required", required),
      minLength: minLength(8),
    },
    password_confirmation: {
      required: helpers.withMessage("Password is required", required),
      minLength: minLength(8),
    },
  },
  page: {
    title: "Reset Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      app_URL: process.env.VUE_APP_URL,
      newPassword: "",
      password_confirmation: "",
      submitted: false,
      errors: [],
      tryingToReset: false,
      isResetError: false,
      alertError: false,
      error: "",
      alertSuccess: false,
      success: "",
    };
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        axios.post(this.app_URL + "api/resetPassword", {
          newPassword: this.newPassword,
          password_confirmation: this.password_confirmation,
          token: this.$route.params.id
        }).then((res) => {
          if (res.data.error) {
            this.alertError = true;
            this.error = res.data.message;
          } else {
            this.success = res.data.message;
            this.alertSuccess = true;
            setTimeout(() => {
              this.$router.push({ path: '/login' });
            }, 2000)
          }
        }).catch(error => {
          console.error(error);
          this.isResetError = true;
          this.errors = error.response.data.errors.password_confirmation;
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft">
            <div class="row">
              <div class="col-12 text-center py-3">
                <img src="/img/whinchester.4ab63a21.png" alt="whinchester Logo" height="80">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <!-- <div> -->
              <!-- <router-link to="/">
              </router-link>
            </div> -->

            <div class="p-2">
              <b-alert v-model="alertError" class="mb-4" variant="danger" dismissible>{{ error }}</b-alert>
              <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{ errors[0] }}</b-alert>
              <b-alert v-model="alertSuccess" class="mb-4" variant="success" dismissible>{{ success }}</b-alert>
              <form>
                <div class="mb-3">
                  <label for="newPassword">Password</label>
                  <input type="password" v-model="newPassword" class="form-control" id="password"
                    placeholder="Your password here" :class="{ 'is-invalid': submitted && v$.newPassword.$error }" />
                  <div v-for="(item, index) in v$.newPassword.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <div class="pt-3">
                    <label for="confirmPassword">Confirm Password</label>
                    <input type="password" v-model="password_confirmation" class="form-control" id="cnfrm-password"
                      placeholder="Confirm your password here"
                      :class="{ 'is-invalid': submitted && v$.password_confirmation.$error }" />
                    <div v-for="(item, index) in v$.password_confirmation.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button class="btn btn-theme w-md" type="button" @click.prevent="tryToReset">
                      Submit
                    </button>

                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <!-- <p>
            Remember It ?
            <router-link to="/login" class="fw-medium text-primary"
              >Sign In here</router-link
            >
          </p> -->
          <p>
            © {{ new Date().getFullYear() }}Whinchester. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Midriff
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
